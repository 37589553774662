import React, {
  Children,
  isValidElement,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useRef,
  MutableRefObject,
} from 'react';
import GoogleMapReact, { MapOptions } from 'google-map-react';
import { IPageLocationFields } from 'types/contentful';

interface MapProps {
  center?: {
    lat: number;
    lng: number;
  };
  defaultCenter?: {
    lat: number;
    lng: number;
  };
  draggable?: boolean;
  options?: MapOptions;
  defaultZoom?: number;
  forceSetMapBounds?: boolean;
  onApiLoaded?({ map }: { map: google.maps.Map }): void;
}

export interface Location {
  fields?: IPageLocationFields;
  slug?: string;
}

export default function Map({
  onApiLoaded,
  children,
  forceSetMapBounds = true,
  ...props
}: PropsWithChildren<MapProps>): ReactElement {
  const mapInstance = useRef() as MutableRefObject<google.maps.Map>;

  /**
   * Creates a string of location lat/lng coords to help
   * useEffect detect changes.
   * @returns
   */
  const haveLocationsChanged = (): string => {
    return Children.map(
      children as ReactElement[],
      (child: ReactElement<{ lat: number; lng: number }>): string => {
        if (!isValidElement(child)) return '';
        return `${child.props.lat}, ${child.props.lng}`;
      }
    )?.join(' ');
  };

  /**
   * Sets map bounds to account for map marker updates.
   * @returns void
   */
  const setMapBounds = (): void => {
    if (Children.count(children) <= 1 || !mapInstance.current) return undefined;
    const bounds = new google.maps.LatLngBounds();
    Children.map(children, (child) => {
      if (isValidElement(child)) bounds.extend({ lat: child.props.lat, lng: child.props.lng });
    });
    if (props.center) bounds.extend(props.center);
    mapInstance.current.fitBounds(bounds, 40);
  };
  useEffect(setMapBounds, [haveLocationsChanged(), props.center, forceSetMapBounds]);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY as string,
        libraries: ['places'],
      }}
      defaultZoom={props.defaultZoom || 16}
      onGoogleApiLoaded={({ map }) => {
        mapInstance.current = map;
        setMapBounds();
        if (onApiLoaded) onApiLoaded({ map });
      }}
      yesIWantToUseGoogleMapApiInternals
      {...props}
    >
      {children}
    </GoogleMapReact>
  );
}
